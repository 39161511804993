// HELPER FUNCTIONS
import $ from 'jquery';
export function isPriceInputHidden(formElementsObj) {
  return (
    formElementsObj.newPriceInput === null ||
    formElementsObj.newPriceInput === undefined ||
    formElementsObj.orgCanEditPrice !== 'True'
  );
}

export function shouldSubmit(priceInputHidden, priceInputHasPrice) {
  return (
    (!priceInputHidden && priceInputHasPrice) ||
    (priceInputHidden && !priceInputHasPrice)
  );
}
export function validateNewPrice(formElementsObj) {
  /** Consider newPriceInput is not hidden
   * bcz there was no price returned so user must enter a valid price */
  let newPriceInputValue = formElementsObj.newPriceInput.value;
  if (
    (formElementsObj.newPriceInput.hidden == false && !newPriceInputValue) ||
    (formElementsObj.newPriceInput.hidden == false &&
      newPriceInputValue == '') ||
    (formElementsObj.newPriceInput.hidden == false &&
      newPriceInputValue < formElementsObj.MINIMUM_PRICE) ||
    (formElementsObj.newPriceInput.hidden == false && isNaN(newPriceInputValue))
  ) {
    formElementsObj.newPriceInputValueErrMsg.innerHTML =
      'Please Enter the new Total price.';
    formElementsObj.newPriceInput.classList.add('is-invalid');
    formElementsObj.newPriceInput.focus();
    return false;
  } else {
    formElementsObj.newPriceInputValueErrMsg.textContent = '';
    if (
      formElementsObj.newPriceInput &&
      formElementsObj.newPriceInput.hidden == false
    ) {
      formElementsObj.newPriceInput.classList.remove('is-invalid');
      formElementsObj.newPriceInput.classList.add('is-valid');
    }
    return true;
  }
}

export function calculateTax(
  newPriceAmount,
  shouldMultiplyQuantity,
  formElementsObj,
) {
  const price = parseFloat(newPriceAmount);
  if (isNaN(price)) {
    throw new Error('Invalid newPriceAmount. Please provide a valid number.');
  }

  const { VAT_PERCENTAGE, quantity, PERCENTAGE_BASE } = formElementsObj;
  if (isNaN(VAT_PERCENTAGE) || isNaN(PERCENTAGE_BASE)) {
    throw new Error(
      'Invalid VAT_PERCENTAGE or PERCENTAGE_BASE in formElementsObj.',
    );
  }

  if (isNaN(quantity?.value)) {
    throw new Error('Invalid quantity object value.');
  }

  const baseTax = price * (VAT_PERCENTAGE / (VAT_PERCENTAGE + PERCENTAGE_BASE));
  if (shouldMultiplyQuantity && quantity?.value) {
    const qty = parseFloat(quantity.value.trim());
    return (baseTax * qty).toFixed(2);
  }

  return baseTax.toFixed(2);
}

export const updateTaxAndTotalAmount = (
  formElementsObj,
  taxHandlersAndArguments,
) => {
  let newPriceAmount = formElementsObj.newPriceInput.value.trim();
  let organisationCurrency =
    formElementsObj.organisationCurrency.innerText.trim();
  if (
    newPriceAmount == '' ||
    isNaN(parseFloat(newPriceAmount)) ||
    newPriceAmount < formElementsObj.MINIMUM_PRICE
  ) {
    formElementsObj.taxText.innerText = '';
    formElementsObj.totalText.innerText = '';
    formElementsObj.subtotalText.innerText = '';
  } else {
    let multiplyQuantity = false;
    let taxAmount = taxHandlersAndArguments.calculateTax(
      newPriceAmount,
      multiplyQuantity,
      formElementsObj,
    );
    formElementsObj.taxText.innerText = `${organisationCurrency} ${taxAmount}`;
    formElementsObj.subtotalText.innerText = `${organisationCurrency} ${(
      newPriceAmount - taxAmount
    ).toFixed(2)}`;
  }
};

export const formateDate = (date, isBackendCompatible) => {
  if (!isBackendCompatible) {
    // Format the date and time
    let options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    let formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(
      date,
    );
    return formattedDateTime;
  } else {
    return date.toISOString().substring(0, 10);
  }
};

export const orderDateIsFilled = orderDateInput => {
  if (!orderDateInput.value) {
    return false;
  } else {
    return true;
  }
};

export const getCurrentDateTime = formateDateHandler => {
  let currentDateTime = new Date();
  return formateDateHandler.formateDate(currentDateTime, false);
};

export const getSelectedOptionText = selectedOption => {
  return selectedOption.parentElement.querySelector('.select2-result__title')
    .textContent;
};

export const validateDate = (selectedDate, formElementsObj) => {
  // Get today's date in 'YYYY-MM-DD' format
  let today = new Date();

  if (selectedDate > today) {
    formElementsObj.orderDateError.innerText =
      'Please select a date that is not in the future';
    formElementsObj.orderDateInput.focus();
    formElementsObj.orderDateInput.classList.add('is-invalid');
    return false;
  } else {
    formElementsObj.orderDateError.innerText = '';
    formElementsObj.orderDateInput.classList.remove('is-invalid');
    formElementsObj.orderDateInput.classList.add('is-valid');
    return true;
  }
};

export const validateOrderDate = (
  formElementsObj,
  ValidateOrderDateHandlers,
) => {
  let orderDateIsValid;
  if (!formElementsObj.orderDateInput.value) {
    orderDateIsValid = true;
    return orderDateIsValid;
  } else {
    let selectedDate = new Date(formElementsObj.orderDateInput.value);
    orderDateIsValid = ValidateOrderDateHandlers.validateDate(
      selectedDate,
      formElementsObj,
    );
    return orderDateIsValid;
  }
};

export const displaySummaryDataFromFormData = (formElementsObj, handlers) => {
  let { getCurrentDateTimeHandlers } = handlers;
  let senderElement = formElementsObj.senderElement;
  let recipientElement = formElementsObj.recipientElement;
  let originElement = formElementsObj.originElement;
  let destinationElement = formElementsObj.destinationElement;

  let lengthText = formElementsObj.lengthInput.value.trim();
  let widthText = formElementsObj.widthInput.value.trim();
  let heightText = formElementsObj.heightElement.value.trim();
  let weightText = formElementsObj.weightInput.value.trim();
  let orderDateInput = formElementsObj.orderDateInput;
  let quantityText = formElementsObj.quantity.value.trim();

  let chargedToText = handlers.getSelectedOptionText(senderElement);

  let recipientNameText = handlers.getSelectedOptionText(recipientElement);
  let destinationText = handlers.getSelectedOptionText(destinationElement);
  let originText = handlers.getSelectedOptionText(originElement);
  let senderNameText = handlers.getSelectedOptionText(senderElement);
  let classOfServiceText;

  if (formElementsObj.classOfServiceElement != undefined) {
    let selectedClassOfServiceText =
      formElementsObj.classOfServiceElement.options !== undefined
        ? formElementsObj.classOfServiceElement.options[
            formElementsObj.classOfServiceElement.selectedIndex
          ].text
        : 'Default Class Of Service';
    classOfServiceText = selectedClassOfServiceText;
  }
  let orderDateText = handlers.orderDateIsFilled(orderDateInput)
    ? handlers.formateDate(new Date(orderDateInput.value), false)
    : handlers.getCurrentDateTime(getCurrentDateTimeHandlers);
  formElementsObj.weightText.innerText = `${weightText}Kg`;
  formElementsObj.dimensionText.innerText = `${lengthText}cmX${widthText}cmX${heightText}cm`;
  formElementsObj.quantityText.innerText = quantityText;
  formElementsObj.destinationText.innerText = destinationText;
  formElementsObj.originText.innerText = originText;
  formElementsObj.senderNameText.innerText = senderNameText;
  formElementsObj.recipientNameText.innerText = recipientNameText;
  formElementsObj.classOfServiceText.innerText = classOfServiceText;
  formElementsObj.orderDateText.innerText = orderDateText;
  formElementsObj.chargedToText.innerText = chargedToText;
};

export const showNewPriceInputHideSubtotalText = formElementsObj => {
  if (formElementsObj.newPriceInput !== null) {
    formElementsObj.priceDisplayWrapper?.removeAttribute('hidden');
    formElementsObj.newPriceInput?.removeAttribute('hidden');
    formElementsObj.totalText?.setAttribute('hidden', true);
    formElementsObj.newPriceInput?.focus();
  } else {
    formElementsObj.priceDisplayWrapper?.setAttribute('hidden', true);
    formElementsObj.totalText?.removeAttribute('hidden');
  }
};

export async function fetchPartnerPaymentTerm(
  orderPrice,
  formElementsObj,
  handlers,
) {
  /** Fetch Partners Payment term and Display on the screen
   * Set to zero when you just need to fetch the payment term without considering checking credit limit
   * When you need to fetch the payment term as well as the to check if they are within credit limit, pass real price
   */
  try {
    let paymentTermErrorMessage = formElementsObj.paymentTermErrorMessage;
    let paymentTermErrorMessageContainer =
      formElementsObj.paymentTermErrorMessageContainer;

    let senderId = formElementsObj.senderElement.value;
    let partnerData = JSON.stringify({
      partner: senderId,
      order_price: orderPrice,
    });

    formElementsObj.pricingSpinnerOverlay.removeAttribute('hidden');
    const response = await fetch(`/partners/api/partner/payment_term`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'X-CSRFToken': formElementsObj.csrf_token.value,
        'Content-Type': 'application/json',
      },
      body: partnerData,
    });
    const data = await response.json();
    if (data.errors) {
      paymentTermErrorMessage.innerHTML = 'Please select a valid payment term';
      paymentTermErrorMessageContainer.removeAttribute('hidden');
    } else {
      // populate the payment term input
      handlers.populatePaymentTerm(data, formElementsObj);
      paymentTermErrorMessageContainer.setAttribute('hidden', false);
      paymentTermErrorMessage.innerHTML = '';
      formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
    }
  } catch (error) {
    formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
    console.error(error);
  }
}

export const populatePaymentTerm = (data, formElementsObj) => {
  let selectedOption = document.createElement('option');
  selectedOption.value = data.payment_term;
  selectedOption.text = data.name;
  formElementsObj.paymentTermElement.appendChild(selectedOption);
  $('#paymentTermInput').val(selectedOption.value);
  let organisationPaymentTerms = data.org_payment_terms;
  if (organisationPaymentTerms.length >= 1) {
    organisationPaymentTerms.forEach(paymentTerm => {
      let option = document.createElement('option');
      option.value = paymentTerm.pk;
      option.text = paymentTerm.name;
      formElementsObj.paymentTermElement.appendChild(option);
    });
  }
  $(formElementsObj.paymentTermElement).select2({
    theme: 'bootstrap-5',
  });
};

export async function fetchParcelPrice(
  formElementsObj,
  fetchParcelPriceDependancies,
) {
  /** Fetch Price and Display on the screen */
  let orderPrice = 0;
  let {
    displaySummaryDataFromFormDataHandlers,
    fetchPartnerPaymentTermHandlers,
  } = fetchParcelPriceDependancies;
  try {
    fetchParcelPriceDependancies.displaySummaryDataFromFormData(
      formElementsObj,
      displaySummaryDataFromFormDataHandlers,
    );
    formElementsObj.pricingSpinnerOverlay.removeAttribute('hidden');
    // show new price form and hide the pricing display wrapper
    fetchParcelPriceDependancies.showNewPriceInputHideSubtotalText(
      formElementsObj,
    );
    // if organization has not enabled price editing do not show submit button
    if (formElementsObj.orgCanEditPrice.innerHTML.trim() == 'False') {
      formElementsObj.submitParcelItemBtn.setAttribute('hidden', true);
      formElementsObj.saveParcelAndShipmentBtn.setAttribute('hidden', true);
    } else {
      formElementsObj.submitParcelItemBtn.removeAttribute('hidden');
      formElementsObj.saveParcelAndShipmentBtn.removeAttribute('hidden');
      formElementsObj.editPriceBtn.setAttribute('hidden', true);
    }
    // get the partners payment term
    await fetchParcelPriceDependancies.fetchPartnerPaymentTerm(
      orderPrice,
      formElementsObj,
      fetchPartnerPaymentTermHandlers,
    );
    formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
  } catch (error) {
    formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
    console.error(error);
  }
}

export const displayAlertMessage = (message, messageType, formElementsObj) => {
  if (messageType == 'success') {
    formElementsObj.alertContainerWrapper.classList.add(
      'alert-success',
      'show',
    );
    formElementsObj.alertPrimaryTextContainer.innerText = 'Success';
  } else {
    formElementsObj.alertContainerWrapper.classList.add('alert-danger', 'show');
    formElementsObj.alertPrimaryTextContainer.innerText = 'Error';
  }
  formElementsObj.alertContainerWrapper.removeAttribute('hidden');
  formElementsObj.alertSecondaryTextContainer.innerText = message;
};

export async function postParcelItemData(
  parcelItemformDataWithPrice,
  formElementsObj,
  postParcelItemDataHandlers,
) {
  /**Post data needed to create a parcel */
  try {
    const response = await fetch(formElementsObj.submitUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'X-CSRFToken': formElementsObj.csrf_token.value,
        'Content-Type': 'application/json',
      },
      body: parcelItemformDataWithPrice,
    });
    const data = await response.json();
    let alertMessage = data.data;
    let messageType = data.message;
    if (data.message === 'error') {
      formElementsObj.errorMessageText.innerHTML = data.data;
      formElementsObj.errorMessageContainer.removeAttribute('hidden');
      formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
    } else {
      postParcelItemDataHandlers.displayAlertMessage(
        alertMessage,
        messageType,
        formElementsObj,
      );
      formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
      // parcel created successfully, redirect to master detail
      window.location = data.success_url;
      // clearform input fields
      formElementsObj.salesOrderLineForm.reset();
    }
  } catch (error) {
    formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
    console.error(error);
  }
}

export function extractAllSaleOrderLineItemFormData(formElementsObj, handlers) {
  // Destructure form elements for easier access
  const {
    senderElement,
    recipientElement,
    originElement,
    destinationElement,
    weightInput,
    widthInput,
    heightElement,
    lengthInput,
    quantity,
    descriptionInput,
    classOfServiceElement,
    salesOrderIdContainer,
    paymentTermElement,
    orderDateInput,
  } = formElementsObj;

  // Trimmed input values
  const senderInputValue = senderElement.value.trim();
  const recipientInputValue = recipientElement.value.trim();
  const originInputValue = originElement.value.trim();
  const destinationInputValue = destinationElement.value.trim();
  const weightInputValue = weightInput.value.trim();
  const widthInputValue = widthInput.value.trim();
  const heightInputValue = heightElement.value.trim();
  const lengthInputValue = lengthInput.value.trim();
  const quantityInputValue = quantity.value.trim();
  const descriptionInputValue = descriptionInput.value.trim();

  const classOfServiceInput =
    classOfServiceElement && classOfServiceElement.value.trim() !== '---------'
      ? classOfServiceElement.value.trim()
      : '';

  // Prepare data for creating the parcel request
  const requestCreateParcelDataDict = {
    saveAsDraft: true,
    sku_id: '',
    organisation_id: '',
    class_of_service_id: classOfServiceInput,
    sender_id: senderInputValue,
    recipient_id: recipientInputValue,
    origin_id: originInputValue,
    destination_id: destinationInputValue,
    description: descriptionInputValue,
    weight: parseFloat(weightInputValue),
    width: parseFloat(widthInputValue),
    height: parseFloat(heightInputValue),
    length: parseFloat(lengthInputValue),
    quantity: parseFloat(quantityInputValue),
    unit_price: 0,
    tax_amount: 0,
    total_price: 0,
    sales_order_id: salesOrderIdContainer.textContent.trim(),
    saveAndEditPrice: false,
    rate_card_id: '',
    rating_table_id: '',
    payment_term_id: paymentTermElement.value,
    orderDate: handlers.orderDateIsFilled(orderDateInput)
      ? handlers.formateDate(new Date(orderDateInput.value), true)
      : handlers.formateDate(new Date(), true),
  };

  return requestCreateParcelDataDict;
}

export async function refetchParcelPriceBeforeSubmit(
  parcelItemformData,
  saveAndEditPrice,
  saveAsDraft,
  formElementsObj,
  refetchParcelPriceBeforeSubmitHandlers,
) {
  try {
    const {
      pricingSpinnerOverlay,
      newPriceInput,
      quantity,
      orgVATTaxRateElement,
    } = formElementsObj;
    const { postParcelItemData, calculateTax, postParcelItemDataHandlers } =
      refetchParcelPriceBeforeSubmitHandlers;
    pricingSpinnerOverlay.removeAttribute('hidden');

    // Fetch and process form data
    const newPriceValue = newPriceInput.value.trim();
    const quantityInputValue = quantity.value.trim();
    const multiplyQuantity = false;

    // Prepare parcel item data
    const parcelItemData = {
      ...parcelItemformData,
      saveAndEditPrice,
      saveAsDraft,
      total_price: parseFloat(newPriceValue).toFixed(2),
      unit_price: parseFloat(newPriceValue / quantityInputValue).toFixed(2),
      tax_amount: calculateTax(
        newPriceValue,
        multiplyQuantity,
        formElementsObj,
      ),
      tax_rate: parseInt(orgVATTaxRateElement.textContent),
    };

    const requestCreateParcelData = JSON.stringify(parcelItemData);

    await postParcelItemData(
      requestCreateParcelData,
      formElementsObj,
      postParcelItemDataHandlers,
    );
  } catch (error) {
    formElementsObj.pricingSpinnerOverlay.setAttribute('hidden', true);
    console.error(error);
  }
}

export function validateSender({ senderElement, senderErrorContainer }) {
  if (senderElement.value === '') {
    senderErrorContainer.innerText = 'Please select a valid option.';
    return false;
  } else {
    senderErrorContainer.innerText = '';
    return true;
  }
}

export function validateOrigin({
  senderElement,
  originElement,
  originErrorContainer,
}) {
  if (senderElement.value === '' && originElement.value === '') {
    originErrorContainer.innerText =
      'Please select a valid sender option to be able to select origin location.';
    return false;
  } else if (originElement.value === '') {
    originErrorContainer.innerText = 'Please select a valid option.';
    return false;
  } else {
    originErrorContainer.innerText = '';
    return true;
  }
}

export function validateDestination({
  recipientElement,
  destinationElement,
  destinationErrorElement,
}) {
  if (recipientElement.value === '' && destinationElement.value === '') {
    destinationErrorElement.innerText =
      'Please select a valid recipient option to be able to select destination location.';
    return false;
  } else if (destinationElement.value === '') {
    destinationErrorElement.innerText = 'Please select a valid option.';
    return false;
  } else {
    destinationErrorElement.innerText = '';
    return true;
  }
}

export function validateRecipient({ recipientElement, recipientErrorElement }) {
  if (recipientElement.value === '') {
    recipientErrorElement.innerText = 'Please select a valid option.';
    return false;
  } else {
    recipientErrorElement.innerText = '';
    return true;
  }
}
